<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 学校列表</van-col
        >
      </van-row>
      <Sift :callback="siftCondition" ref="sift" />
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveSchoolPage"
      >
        <template #default>
          <div
            class="school"
            v-for="school in schoolList"
            :key="school.code"
            @click="detail(school.code)"
          >
            <div class="name text-short">
              {{ school.name }}
            </div>
            <van-row>
              <van-col span="12" class="type"
                >学校类型：<van-tag
                  :color="COLOR_M"
                  v-if="school.type === 'PMS'"
                  >小学</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'JNS'"
                  >初中</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'HGS'"
                  >高中</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S06'"
                  >完全中学</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S09'"
                  >九年一贯制</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S12'"
                  >十二年一贯制</van-tag
                ></van-col
              >
              <van-col span="12" class="region"
                >所在城市：<van-tag :color="COLOR_M">{{
                  school.regionName
                }}</van-tag></van-col
              >
            </van-row>
            <div class="address text-short">学校地址：{{ school.address }}</div>
          </div>
          <van-row v-if="schoolList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="EDU"
      module-label="EDU_SEARCH_INDEX"
    />
    <Clue ref="clue" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
